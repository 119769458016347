import Vue from "vue";
import VueRouter from "vue-router";
import BasicLayout from "@/components/basic-layout";

Vue.use(VueRouter);

const routes = [
    {
      path: "/",
      component: BasicLayout,
      children: [
        {
          path: "/",
          name: "home",
          component: () => import(/* webpackChunkName: "home" */ "../pages/home/index.vue")
        },
        {
          path: "/list/:category/:category_id?",
          name: "list",
          component: () => import(/* webpackChunkName: "list" */ "../pages/list/index.vue")
        },
        {
          path: "/search/:search/:category?/:category_id?",
          name: "search",
          component: () => import(/* webpackChunkName: "search" */ "../pages/list/index.vue")
        },
        {
          path: "/download/:category/:category_id?",
          name: "download",
          component: () => import(/* webpackChunkName: "download" */ "../pages/download/index.vue")
        },
        {
          path: "/article/:category/:category_id?/:article_id?/:search?",
          name: "article",
          component: () => import(/* webpackChunkName: "article" */ "../pages/article/index.vue")
        },
        {
          path: "/certificate/:category/:category_id?",
          name: "certificate",
          component: () => import(/* webpackChunkName: "certificate" */ "../pages/certificate/index.vue")
        },
        {
          path: "/certificate-viewthread/:search/:category/:category_id?",
          name: "certificate-viewthread",
          component: () => import(/* webpackChunkName: "certificate-viewthread" */ "../pages/article/index.vue")
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import(/* webpackChunkName: "profile" */ "../pages/profile/index.vue")
        },
        {
          path: "/change-password",
          name: "change-password",
          component: () => import(/* webpackChunkName: "change-password" */ "../pages/profile/change-password.vue")
        },
        {
          path: "/login",
          name: "login",
          component: () => import(/* webpackChunkName: "login" */ "../pages/login/index.vue")
        },
        {
          path: "/register",
          name: "register",
          component: () => import(/* webpackChunkName: "register" */ "../pages/register/index.vue")
        }
      ]
    }
  ],

  router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
  });

export default router;