import go from "@/utils/go";
export default {
  methods: {
    handleMenuClick(item){
      if(item?.category){
        go.to(item?.type, {
          category: item?.category,
          category_id: item?.category_id,
          article_id: item?.article_id,
          search: item?.search
        });
      }
      else {
        go.to(item?.type);
      }
    }
  }
};