import go from "@/utils/go";
import qs from "qs";
import axios from "axios";
import { message } from "ant-design-vue";
import { baseUrl } from "@/configs";

// create an axios instance
const service = axios.create({
  baseURL: baseUrl, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.method = config.method || "GET";
    if(/POST/i.test(config.method)){
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
      config.data = qs.stringify(config.data);
      delete config.params;
    }
    else {
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  error => {
    // do something with request error
    // eslint-disable-next-line no-console
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response?.data;

    // if the custom code is not 20000, it is judged as an error.
    if(-1 == res?.code){
      message.error("登录已过期, 请重新登录");
      sessionStorage.removeItem("hqcc$user");
      return go.to("login");
    }
    else if(1 !== res?.code){
      message.error(res?.msg);
      return Promise.reject(
        new Error(res.msg)
      );
    }

    return Array.isArray(res?.data) ? res?.data : {
      ... res?.data,
      $respond: true
    };
  },
  error => {
    // eslint-disable-next-line no-console
    console.log(`err${error}`); // for debug
    message.error(error?.message);
    return Promise.reject(error);
  }
);

export default data => service(data);
export const getToken = () => JSON.parse(
  sessionStorage.getItem("hqcc$user")
)?.token || "";