<template>
  <div class="basic-layout">
    <router-view />
    <footer-bottom
      v-if="!loading"
      :data="data"
    />
    <div class="right-toolbar">
      <a class="button helper">
        <div class="toolbar-inner">
          {{data.supervise}}
        </div>
      </a>
      <a class="button qrcode">
        <div class="toolbar-inner">
          <img :src="data.mp_image" />
        </div>
      </a>
      <a
        class="button gotop"
        @click="() => handleTop()"
      />
    </div>
  </div>
</template>

<script>
import FooterBottom from "@/components/footer-bottom";
import { bottomNav } from "@/api/home";
import { getSettings } from "@/api/setting";
import { getUserInfo } from "@/api/user";
import { getToken } from "@/utils/http-request";
import { mapMutations } from "vuex";
export default {
  name: "BasicLayout",
  components: {
    FooterBottom
  },
  data(){
    return {
      loading: true,
      data: {}
    };
  },
  methods: {
    ... mapMutations("user", ["setUser"]),
    handleTop(){
      self?.scrollTo?.(0, 0);
    },
    async main(){
      const
        data = await getSettings(),
        dl = await bottomNav(),
        token = getToken();
      if(token){
        const user = await getUserInfo({
          token
        });
        this.setUser(user);
      }
      this.$set(this, "loading", false);
      this.$set(this, "data", {
        ... data,
        dl
      });
    }
  },
  mounted(){
    this?.main?.();
  }
};
</script>
<style lang="less" scoped>
.basic-layout {
  position: relative;
  .right-toolbar {
    position: fixed;
    right: 20px;
    top: 50vh;
    .button {
      width: 48px;
      height: 48px;
      background: #082f70 no-repeat center center;
      background-size: 50%;
      margin-bottom: 10px;
      position: relative;
      display: block;
      .toolbar-inner {
        display: none;
        color: #333333;
        position: absolute;
        right: 68px;
        background: #f0f0f0;
        padding: 20px;
        border: 1px solid #cccccc;
        img {
          width: 160px;
          height: 160px;
        }
      }
      &:hover .toolbar-inner {
        display: block;
      }
      &.helper {
        background-image: url("~@/static/images/index__helper.jpg");
      }
      &.qrcode {
        background-image: url("~@/static/images/index__qrcode.jpg");
      }
      &.gotop {
        background-image: url("~@/static/images/index__gotop.jpg");
      }
    }
  }
}
</style>