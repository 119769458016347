var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-bottom"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"flex"},_vm._l((_vm.data.dl),function(item,i){return _c('dl',{key:i,staticClass:"flex-item"},[_c('dt',[_vm._v(" "+_vm._s(item.label)+" ")]),_vm._l((item.children),function(subItem,j){return _c('dd',{key:`${i}-${j}`,on:{"click":() => _vm.handleMenuClick({
            category: item?.category,
            ... subItem
          })}},[_vm._v(" "+_vm._s(subItem.label)+" ")])})],2)}),0),_c('div',{staticClass:"qrcode"},[_c('img',{attrs:{"src":_vm.data.mp_image}}),_c('div',{staticClass:"headline"},[_vm._v(" 机构二维码 ")])]),_c('div',{staticClass:"footnote"},_vm._l((_vm.footnote),function(item,i){return _c('div',{key:i,staticClass:"footnote-item"},_vm._l((item),function(subItem,j){return _c('div',{key:`${i}-${j}`,staticClass:"flex-item"},[_c('i',{class:[
              'icon',
              `icon-${subItem.icon}`
            ]}),_vm._v(_vm._s(subItem.label)+": "+_vm._s(subItem.value)+" ")])}),0)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }