import http from "@/utils/http-request";

export const login = data => http({
  url: "user/login",
  method: "POST",
  data
});

export const register = data => http({
  url: "user/register",
  method: "POST",
  data
});

export const getUserInfo = params => http({
  url: "user/getUserInfo",
  params
});

export const changePassword = data => http({
  url: "user/editPassword",
  method: "POST",
  data
});

export const editUser = data => http({
  url: "user/editUser",
  method: "POST",
  data
});