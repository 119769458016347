import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import {
  Alert as AAlert,
  Button as AButton,
  Carousel as ACarousel,
  Checkbox as ACheckbox,
  DatePicker as ADatePicker,
  Form as AForm,
  Input as AInput,
  Radio as ARadio,
  Select as ASelect,
  Switch as ASwitch,
  Table as ATable,
  Tabs as ATabs,
  Upload as AUpload,
  Modal,
  message,
  notification
} from "ant-design-vue";

Vue.use(AAlert);
Vue.use(AButton);
Vue.use(ACarousel);
Vue.use(ACheckbox);
Vue.use(ADatePicker);
Vue.use(AForm);
Vue.use(AInput);
Vue.use(ARadio);
Vue.use(ASelect);
Vue.use(ASwitch);
Vue.use(ATable);
Vue.use(ATabs);
Vue.use(AUpload);

Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$info = Modal.info;
Vue.prototype.$success = Modal.success;
Vue.prototype.$error = Modal.error;
Vue.prototype.$warning = Modal.warning;
Vue.prototype.$message = message;
Vue.prototype.$notification = notification;

Vue.config.productionTip = false;

self.router = router;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");