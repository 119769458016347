import utils from "@/utils";
export const user = {
  token: "",
  username: "",
  mobile: "",
  email: ""
};

export const archive = JSON.parse(
  JSON.stringify(user)
);

export default {
  namespaced: true,
  state: user,
  getters: {},
  mutations: {
    setUser(state, payload){
      /*
        🚨
        该方法负责设置navbar, 一般放在页面组件的onLoad生命周期中,
        但也有例外, 如果当前页作为一个 tabbar 页面, 则改放入onShow内,
        否则页面缓存后将不走onLoad钩子, 致使navbar不再被更新
      */
      const result = {
        ... state,
        ... payload
      };
      sessionStorage.setItem(
        "hqcc$user",
        JSON.stringify(result)
      );
      utils.reactive(state, result);
    }
  },
  actions: {
    logout({ commit }){
      sessionStorage.removeItem("hqcc$user");
      commit("setUser", archive);
    }
  }
};