export default {
  to: (name, query) => {
    self?.scrollTo?.(0, 0);
    self?.router.push({
      name,
      ... query ? {
        params: query
      } : {}
    }).catch(e => e);
  }
};