<template>
  <div class="footer-bottom">
    <div class="container">
      <div class="flex">
        <dl
          class="flex-item"
          v-for="(item, i) in data.dl"
          :key="i"
        >
          <dt>
            {{item.label}}
          </dt>
          <dd
            v-for="(subItem, j) in item.children"
            :key="`${i}-${j}`"
            @click="() => handleMenuClick({
              category: item?.category,
              ... subItem
            })"
          >
            {{subItem.label}}
          </dd>
        </dl>
      </div>
      <div class="qrcode">
        <img :src="data.mp_image" />
        <div class="headline">
          机构二维码
        </div>
      </div>
      <div class="footnote">
        <div
          class="footnote-item"
          v-for="(item, i) in footnote"
          :key="i"
        >
          <div
            class="flex-item"
            v-for="(subItem, j) in item"
            :key="`${i}-${j}`"
          >
            <i
              :class="[
                'icon',
                `icon-${subItem.icon}`
              ]"
            />{{subItem.label}}: {{subItem.value}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins";
export default {
  mixins: [
    mixins
  ],
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
  },
  // eslint-disable-next-line max-lines-per-function
  data(){
    return {
      footnote: [],
      qrcode: ""
    };
  },
  methods: {
    // eslint-disable-next-line max-lines-per-function
    main(){
      const {
        address,
        zipcode,
        mobile,
        mailbox,
        supervise,
        filings,
        copyright
      } = this?.data;
      this.$set(this, "footnote", [
        [
          {
            icon: "zipcode",
            label: "邮编",
            value: zipcode
          },
          {
            icon: "contact",
            label: "联系方式",
            value: mobile
          },
          {
            icon: "address",
            label: "地址",
            value: address
          },
          {
            icon: "email",
            label: "举报邮箱",
            value: mailbox
          },
          {
            icon: "contact",
            label: "监督热线",
            value: supervise
          }
        ],
        [
          {
            icon: "compliance",
            label: "备案号",
            value: filings
          },
          {
            icon: "copyright",
            label: "版权信息",
            value: copyright
          }
        ]
      ]);
    }
  },
  mounted(){
    this.main();
  }
};
</script>

<style lang="less" scoped>
.footer-bottom {
  padding: 45px 0 38px;
  background-color: #f2f2f2;
  .container {
    position: relative;
  }
  .flex {
    justify-content: space-around;
    .flex-item {
      dt {
        font-size: 20px;
        color: #767676;
        line-height: 20px;
        padding-bottom: 25px;
      }
      dd {
        cursor: pointer;
        color: #6f6f6f;
      }
    }
  }
  .qrcode {
    position: absolute;
    top: 100px;
    right: 200px;
    img {
      width: 140px;
      height: 140px;
    }
    .headline {
      text-align: center;
      color: #767676;
    }
  }
  .footnote {
    margin-top: 40px;
    .footnote-item {
      display: flex;
      .flex-item {
        margin-right: 35px;
        color: #767676;
        font-size: 12px;
        line-height: 25px;
        i.icon {
          background: no-repeat left 2px;
          display: inline-block;
          vertical-align: middle;
          width: 20px;
          height: 20px;
          background-size: 15px;
          &.icon-zipcode {
            background-image: url("~@/static/images/index__footer-zipcode.jpg");
          }
          &.icon-contact {
            background-image: url("~@/static/images/index__footer-contact.jpg");
          }
          &.icon-address {
            background-image: url("~@/static/images/index__footer-address.jpg");
          }
          &.icon-email {
            background-image: url("~@/static/images/index__footer-email.jpg");
          }
          &.icon-compliance {
            background-image: url("~@/static/images/index__footer-compliance.jpg");
          }
          &.icon-copyright {
            background-image: url("~@/static/images/index__footer-copyright.jpg");
          }
        }
      }
    }
  }
}
</style>