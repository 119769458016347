import http from "@/utils/http-request";

export const bannerList = params => http({
  url: "index/bannerList",
  params
});

export const shortcutList = params => http({
  url: "index/shortcutList",
  params
});

export const getAddress = params => http({
  url: "index/getAddress",
  params
});

export const noticeList = params => http({
  url: "index/noticeList",
  params,
  cccc: 1
});

export const headerNav = params => http({
  url: "index/headerNav",
  params
});

export const bottomNav = params => http({
  url: "index/bottomNav",
  params
});

export const leftMenu = params => http({
  url: "index/leftMenu",
  params
});

export const getList = params => http({
  url: "index/getList",
  params
});

export const getDetails = params => http({
  url: "index/getDetails",
  params
});

export const getAgency = params => http({
  url: "index/getAgency",
  params
});

export const getJump = params => http({
  url: "index/getJump",
  params
});