import http from "@/utils/http-request";

export const getStore = params => http({
  url: "setting/store",
  params
});

export const getSettings = params => new Promise(
  // eslint-disable-next-line no-async-promise-executor
  async resolve => {
    const settings = JSON.parse(
      localStorage.getItem("settings")
    );
    if(settings?.$respond){
      resolve(settings);
    }
    else {
      const res = await getStore(params);
      localStorage.setItem(
        "settings",
        JSON.stringify(res)
      );
      resolve(res);
    }
  }
);